import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/jwt.service';
import EventBus from '@/event-bus';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common['Authorization'] = JwtService.getToken();
    Vue.axios.defaults.headers.common['Content-Type'] = "application/json";
    let _u = JwtService.getCurrentUser();
    if (_u && _u.l) Vue.axios.defaults.headers.common['Language'] = _u.l;
    // if (_u && _u.c) Vue.axios.defaults.headers.common['companyId'] = _u.c;
    // else {
    //   let _companyId = JwtService.getSelectedCompanyId();
    //   if (_companyId && _companyId !== '')
    //     Vue.axios.defaults.headers.common['companyId'] = _companyId;
    // }
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      throw error;
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    this.setHeader()
    return Vue.axios.get(`${resource}`).catch((error) => {
      // console.log(error);
      throw error;
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    this.setHeader()
    return (
      Vue.axios
        .post(resource, params,{
          headers: {
            'Content-Type': 'application/json',
          },
        })
        // .then(({ data }) => {
        //   if (data.resultCode !== 200) {
        //     EventBus.$emit('errors', data.resultMessage)
        //   }
        // })
        .catch(function (error) {
          if (error.response) {
            // console.log(error.response.data);
            // console.log(error.response.status);
            if (
              error.response.data.error &&
              error.response.data.error == true &&
              error.response.data.errors.length > 0
            ) {
              EventBus.$emit('errors', error.response.data.errors[0].message);
              throw error.response.data;
            }
          }
          throw 'Error';
        })
    );
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeader()
    return Vue.axios.patch(`${resource}/${slug}`, params).catch((error) => {
      if (error.response && error.response.data) {
        EventBus.$emit('errors', error.response.data.ResultMessage);
        throw error.response.data;
      }

      throw 'Error';
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    this.setHeader()
    return Vue.axios
      .put(`${resource}`, params, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          EventBus.$emit('errors', error.response.data.ResultMessage);
          throw error.response.data;
        }

        throw 'Error';
      });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params).catch((error) => {
      if (error.response && error.response.data) {
        EventBus.$emit('errors', error.response.data.ResultMessage);
        throw error.response.data;
      }

      throw 'Error';
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, params = null) {
    this.setHeader()

    if (params && params != null) {
      return Vue.axios
        .delete(resource, {
          data: params,
        })
        .catch((error) => {
          // if (error.response) {
          //   // The request was made and the server responded with a status code
          //   // that falls out of the range of 2xx
          //   console.log(error.response.data);
          //   console.log(error.response.status);
          //   console.log(error.response.headers);
          // } else if (error.request) {
          //   // The request was made but no response was received
          //   // `error.request` is an instance of XMLHttpRequest in the browser
          //   // and an instance of http.ClientRequest in node.js
          //   console.log(error.request);
          // } else {
          //   // Something happened in setting up the request that triggered an Error
          //   console.log('Error', error.message);
          // }
          throw error;
        });
    } else {
      return Vue.axios.delete(resource).catch((error) => {
        // console.log(error);
        throw error;
      });
    }
  },
};

export default ApiService;