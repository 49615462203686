import Vue from 'vue';
import Router from 'vue-router';
import store from '@/core/services/store';

//for NavigationDuplicated error.
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

// Import middleware
import verifyRoute from '@/middleware/verifyRoute';
import verifyUser from '@/middleware/verifyUser';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/',
          name: 'anasayfa',
          meta: {
            title: 'Anasayfa | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/Dashboard.vue'),
        },
        {
          path: 'declaration',
          meta: {
            title: 'Emlak Devir | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/layout/SubLayout.vue'),
          children:[
            {
              path: '/',
              name: 'declaration',
              meta: {
                title: 'Emlak Devir | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/declaration/List.vue'),
            },
            {
              path: 'detail',
              name: 'declaration-detail',
              meta: {
                title: 'Emlak Detay | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/declaration/Detail.vue'),
            },
          ]
        },
        
        {
          path: 'reminders',
          name: 'reminders',
          meta: {
            title: 'Hatırlatmalar | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/Reminders.vue'),
        },
        
      ],
    },
    {
      path: '/customer',
      meta: {
        title: 'Müşteriler | Propertize',
        middleware: [verifyUser],
      },
      component: () => import('@/view/layout/Layout.vue'),
      children:[
        {
          path: 'list',
          name: 'customer-list',
          meta: {
            title: 'Müşteriler | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/customer/List.vue'),
        },
        {
          path: 'detail/:id',
          name: 'customer-detail',
          meta: {
            title: 'Müşteri Detay | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/customer/Detail.vue'),
        },
        {
          path: 'add',
          name: 'customer-add',
          meta: {
            title: 'Müşteri Ekle | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/customer/Add.vue'),
        },
      ]
    },
    {
      path:"/properties",
      component: () => import('@/view/layout/Layout'),
      children:[
        {
          path: 'new',
          name: 'new-property',
          meta: {
            title: 'Yeni Varlık Ekle | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/properties/New.vue'),
        },
        {
          path: 'find',
          meta: {
            title: 'Varlıklar | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/layout/SubLayout.vue'),
          children:[
            {
              path: '/',
              name: 'find-property-list',
              meta: {
                title: 'Varlıklar | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/properties/Find.vue'),    
            },
            {
              path: 'detail/:id/',
              name: 'detail-property',
              meta: {
                title: 'Varlık Detayı | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/properties/Detail.vue'),    
            },
          
            {
              path: 'detail/:id/tapuekle/',
              name: 'tapuekle',
              meta: {
                title: 'Varlık Detayı | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/properties/Detail.vue'),    
            },

          ]
        },
      
      ]
    },
    {
      path:"/adverts",
      component: () => import('@/view/layout/Layout'),
      children:[
        {
          path: 'find',
          meta: {
            title: 'İlanlar | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/layout/SubLayout.vue'),

          children:[
            {
              path: '/',
              name: 'find-adverts-list',
              meta: {
                title: 'İlanlar | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/adverts/Find.vue'),
            },
            {
              path: 'detail/:id/',
              name: 'find-adverts-detail',
              meta: {
                title: 'İlan Detayları | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/adverts/Detail.vue'),
            },
          ]
        },
        {
          path: 'add',
          name: 'add-adverts',
          meta: {
            title: 'Yeni EKLE | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/adverts/Add.vue'),
        },
        
      ]
    },
    
    {
      path:"/transfer",
      component: () => import('@/view/layout/Layout'),
      children:[
        {
          path: 'sales',
          meta: {
            title: 'Satış Devir | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/layout/SubLayout.vue'),
          children:[
            {
              path: '/',
              name: 'sales-transfer',
              meta: {
                title: 'Satış Devir | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/transfer/Sales.vue'),
            },
            {
              path: 'detail/:id',
              name: 'sales-detail',
              meta: {
                title: 'Satış Devir Detay | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/transfer/SalesDetail.vue'),
            },
          ]
        },
        {
          path: 'property',

          meta: {
            title: 'Varlık Devir | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/layout/SubLayout.vue'),
          children:[
            {
              path: '/',
              name: 'property-transfer',
              meta: {
                title: 'Varlık Devir | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/transfer/Property.vue'),
            },
            {
              path: 'detail/:id',
              name: 'property-detail',
              meta: {
                title: 'Varlık Devir Detay | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/transfer/PropertyDetail.vue'),
            },
          ]
        },
        
        {
          path:"edit",
          name: 'edit',
          meta: {
            title: 'Satış Devir | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/transfer/TransferEdit'),
        }
        
      ]
    },
    {
      path:"/lists",
      component: () => import('@/view/layout/Layout'),
      children:[
        {
          path: 'monthly',
          name: 'monthly-list',
          meta: {
            title: 'Aylık Rapor | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/lists/Monthly.vue'),
        },
        {
          path: 'offer',
          name: 'offer-list',
          meta: {
            title: 'Ön Teklifler | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/lists/Offer.vue'),
        },
        {
          path: 'show',
          name: 'show',
          meta: {
            title: 'Yer Gösterim Listesi | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/lists/Show.vue'),
        },
        {
          path: 'sold',
          name: 'sold',
          meta: {
            title: 'Satılanlar | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/lists/Sold.vue'),
        },
        
      ]
    },
    {
      path:"/manage",
      component: () => import('@/view/layout/Layout'),
      children:[
        {
          path: 'bank',
          name: 'bank-manage',
          meta: {
            title: 'Satıcılar | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/Bank.vue'),
        },
        {
          path: 'bank-new',
          name: 'bank-new',
          meta: {
            title: 'Satıcılar Detay | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/BankNew.vue'),
        },
        {
          path: 'bank-details',
          name: 'bank-details',
          meta: {
            title: 'Satıcı Detay | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/BankDetail.vue'),
        },
       
        {
          path: 'company',
          name: 'company-manage',
          meta: {
            title: 'Firma Kartı | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/Company.vue'),
        },
        {
          path: 'users',
          name: 'users-manage',
          meta: {
            title: 'Kullanıcılar | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/users/Users.vue'),
        },
        {
          path: 'users-details',
          name: 'users',
          meta: {
            title: 'Kullanıcı Detay | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/users/UsersDetails.vue'),
        },
        {
          path: 'customer',
          meta: {
            title: 'Müşteriler | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/layout/SubLayout.vue'),
          children:[
            {
              path: '/',
              name: 'customer-manage-list',
              meta: {
                title: 'Müşteriler | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/manage/customer/List.vue'),
            },
            {
              path: 'new',
              name: 'customer-manage-new',
              meta: {
                title: 'Müşteriler | Propertize',
                middleware: [verifyUser],
              },
              component: () => import('@/view/pages/manage/customer/New.vue'),
            },
          ]

        },
        {
          path: 'location',
          name: 'location-manage-list',
          meta: {
            title: 'İl/İlçe/Mahalle-Köy | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/Location.vue'),
        },
        
       
        {
          path: 'feature',
          name: 'feature-manage',
          meta: {
            title: 'Özellik Kategorileri | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/feature/Feature.vue'),
        },
        {
          path: 'feature/details',
          name: 'feature-manage-detay',
          meta: {
            title: 'Özellik Kategorileri | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/feature/FeatureDetails.vue'),
        },
        {
          path: 'purpose',
          name: 'purpose-manage',
          meta: {
            title: 'Fiili Kullanım Amaçları | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/purpose/List.vue'),
        },
        {
          path: 'purpose/new',
          name: 'purpose-manage-new',
          meta: {
            title: 'Fiili Kullanım Amacı Ekle | Propertize',
            middleware: [verifyUser],
          },
          component: () => import('@/view/pages/manage/purpose/New.vue'),
        },
        
      ]
    },
    {
      path: '/',
      component: () => import('@/view/layout/Auth'),
      children: [
        {
          path: '/giris',
          name: 'login',
          meta: {
            title: 'Giriş | DveB Panel',
            middleware: [verifyRoute],
          },
          component: () => import('@/view/pages/auth/Login'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      meta: {
        title: 'Sayfa bulunamadı! | DveB Panel',
      },
      component: () => import('@/view/pages/error/Error.vue'),
    },
  ],
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      store,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  } else {
    return next();
  }
});
export default router;
