import moment from 'moment';

const customFunctions = {
  showSuccess(app) {
    app.$generateNotification(app, 'success', 'İşlem başarıyla tamamlandı.');
  },
  showError(app) {
    app.$generateNotification(
      app,
      'error',
      'Bir hata oluştu, lütfen daha sonra tekrar deneyin.'
    );
  },
  copyWithoutReference(_i) {
    return JSON.parse(JSON.stringify(_i));
  },
  capitalizeFirstLetter(str) {
    return str.toString().charAt(0).toUpperCase() + str.toString().slice(1);
  },
  timeSince(dateStr) {
    if (!dateStr) return '-';

    var date = new Date(dateStr);
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + ' years ago';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' months ago';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' days ago';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' hours ago';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds ago';
  },
  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
  setMomentWithFormat(date, format, utcOffset) {
    if (date) {
      if (!utcOffset) {
        //control utcOffset
        if (date.includes('+')) {
          var splt = date.split('+');
          utcOffset = `+${splt[1]}`;
        }
      }

      if (!format) {
        format = 'L LT';
      }

      if (utcOffset) {
        return moment(date).utcOffset(utcOffset).format(format);
      }
      return moment(date).format(format);
    } else return '-';
  },
  exportCSVFile(items, exportedFilename) {
    // items = '\ufeff' + items;
    var blob = new Blob([items], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  downloadFile(downloadUrl) {
    // var windowReference = window.open();
    // windowReference.location = downloadUrl;
    // window.open(downloadUrl);

    // const link = document.createElement('a');
    // link.href = URL.createObjectURL(new Blob([response.data]));
    // link.download = `report.pdf`;
    // link.click();
    // URL.revokeObjectURL(link.href);

    var link = document.createElement('a');
    if (link.download !== undefined) {
      link.setAttribute('href', downloadUrl);
      link.setAttribute('target', '_blank');
      // link.setAttribute('download', downloadUrl);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  splitContent(val, number) {
    if (val && val.length > number) {
      return val.substring(0, number) + '..';
    }

    return val;
  },
  moneyFixGlobal(val, pieceCount) {
    // console.log(val);
    if ((typeof val === 'number' || typeof val === 'string') && val >= 0) {
      val = parseFloat(val);
      val = val.toFixed(pieceCount);
      val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      // if (val.includes('.000')) {
      //   return val.replace('.000', '.0')
      // } else if (val.includes('.00')) {
      //   return val.replace('.00', '.0')
      // } else {
      //   return val.replace('.00', '')
      // }

      return val;
    } else {
      return '-';
    }
  },
  controlSpace(value) {
    let lengthValue = 20;
    if (value && value.length > lengthValue) {
      let newValue = '';

      let dotList = value.split('.');
      if (dotList.length > 0) {
        let _new = '';
        dotList.forEach(function (item) {
          _new += value.length + item > lengthValue ? `${item}. ` : `${item}.`;
        });
        value = _new.slice(0, -1);
      }

      value.split(' ').forEach(function (item) {
        if (item.length > lengthValue) {
          for (
            let index = 0;
            index < Math.ceil(item.length / lengthValue);
            index++
          ) {
            newValue += `${item.substr(
              index * lengthValue,
              (index == 0 ? 1 : index) * lengthValue
            )} `;
          }
        } else newValue += `${item} `;
      });
      return newValue;
    }
    return value;
  },
  getUserTypeText(userTypeEnum) {
    let _userTypeText = '';

    if (userTypeEnum == 'SystemAdministrator') _userTypeText = 'Sistem Admini';
    else if (
      userTypeEnum == 'CompanyAdministrator' ||
      userTypeEnum == 'Company'
    )
      _userTypeText = 'Firma Admini';
    else if (userTypeEnum == 'CompanyManager')
      _userTypeText = 'Firma Yöneticisi';
    else if (userTypeEnum == 'StandartUser')
      _userTypeText = 'Standart Kullanıcı';
    else _userTypeText = 'Çalışan';

    return _userTypeText;
  },
  getIsActiveBadge(isActive) {
    if (isActive === true)
      return '<span class="badge badge-success rounded-sm">Aktif</span>';
    else return '<span class="badge badge-danger rounded-sm">Pasif</span>';
  },
  getIsStar(star){
    if(star) return '<span class="active-star"><i class="flaticon-star"></i></span>'
  },
  getDataEntryScheduleStatus(status) {
    if (status === 'Planned')
      return '<span class="badge badge-warning rounded-sm">Planlandı</span>';
    else if (status === 'Stopped')
      return '<span class="badge badge-danger rounded-sm">Durduruldu</span>';
    else if (status === 'InProgress')
      return '<span class="badge badge-success rounded-sm">Devam Ediyor</span>';
    else if (status === 'Completed')
      return '<span class="badge badge-primary rounded-sm">Tamamlandı</span>';
  },
  getFormDraftStatus(status) {
    if (status === false || status === 'false')
      return '<span class="badge badge-success font-weight-bolder rounded-sm"><div class="d-flex align-items-center"><i class="flaticon2-correct text-success pr-2"></i> YAYINDA</div></span>';
    else if (status === true || status === 'true')
      return '<span class="badge badge-secondary text-dark-50 font-weight-bolder rounded-sm"><div class="d-flex align-items-center"><i class="flaticon2-file-2 text-dark-50 pr-2"></i> TASLAK</div></span>';
  },
  getDataEntryFormStatus(status) {
    if (status === 'NotFilled')
      return '<span class="badge badge-secondary text-dark-50 font-weight-bolder rounded-sm"><div class="d-flex align-items-center"><i class="flaticon-file-2 text-dark-50 pr-2"></i> DOLDURULMADI</div></span>';
    else if (status === 'Draft')
      return '<span class="badge badge-secondary text-dark-50 font-weight-bolder rounded-sm"><div class="d-flex align-items-center"><i class="flaticon-file-2 text-dark-50 pr-2"></i> TASLAK</div></span>';
    else if (status === 'SentForApprove')
      return '<span class="badge badge-warning text-dark-50 font-weight-bolder rounded-sm"><div class="d-flex align-items-center"><i class="flaticon-clock-1 text-warning pr-2"></i> ONAY BEKLİYOR</div></span>';
    else if (status === 'WaitingForReference')
      return '<span class="badge badge-info text-dark-50 font-weight-bolder rounded-sm"><div class="d-flex align-items-center"><i class="flaticon2-copy text-dark-50 pr-2"></i> REFERANS AKTARIMI BEKLİYOR</div></span>';
    else if (status === 'Approved')
      return '<span class="badge badge-success text-dark-50 font-weight-bolder rounded-sm"><div class="d-flex align-items-center"><i class="flaticon2-correct text-success pr-2"></i> ONAYLANDI</div></span>';
    else if (status === 'SentBack')
      return '<span class="badge badge-danger bg-light-danger font-weight-bolder rounded-sm"><div class="d-flex align-items-center"><i class="flaticon-reply text-danger pr-2"></i> GERİ GÖNDERİLDİ</div></span>';
  },
  getFormStatusType(statusTypeEnum) {
    let _statusTypeText = '';
    if (statusTypeEnum == 'NotFilled') _statusTypeText = 'Doldurulmayan';
    else if (statusTypeEnum == 'Draft') _statusTypeText = 'Taslak';
    else if (statusTypeEnum == 'SentForApprove')
      _statusTypeText = 'Onay Bekliyor';
    else if (statusTypeEnum == 'WaitingForReference')
      _statusTypeText = 'Referans Aktarımı Bekliyor';
    else if (statusTypeEnum == 'Approved') _statusTypeText = 'Onaylandı';
    else if (statusTypeEnum == 'SentBack') _statusTypeText = 'Geri Gönderildi';

    return _statusTypeText;
  },
  getDbOperationTypesEnum(type, item) {
    // insert,
    // insertOrUpdate,
    // update,
    // updateStatus,
    // delete,
    if (
      type === 'openInsertUpdate' ||
      type === 'openFormUpdateArea' ||
      type === 'openFormProcessArea' ||
      type === 'openFormActivityArea'
    ) {
      if (item === '' || item === undefined || item === null) return 'insert';
      else return 'update';
    } else if (type === 'openImportModal') return 'insertOrUpdate';
    else if (type === 'allInActive' || type === 'allActive')
      return 'updateStatus';
    else if (
      type === 'openDeleteHandler' ||
      type === 'allRemove' ||
      type === 'openDeleteHandler_formActivity' ||
      type === 'openDeleteHandler_formProcess'
    )
      return 'delete';
    else if (type === 'dragProcessChange' || type === 'dragActivityChange')
      return 'update';
  },
  getPeriodName(periodEnum) {
    let _periodText = '';

    if (periodEnum == 'Weekly') _periodText = 'Haftalık';
    else if (periodEnum == 'Monthly') _periodText = 'Aylık';
    else if (periodEnum == 'Quarterly') _periodText = '3 Aylık';
    else if (periodEnum == 'Yearly') _periodText = 'Yıllık';
    else _periodText = '-';

    return _periodText;
  },
};

export default customFunctions;
